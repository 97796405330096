import React, { Component } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import logo from "../static/images/logo.png";
import menuBar from "../static/images/menu-bar1.svg";
import applicationSettings from "../static/images/settings_applications.svg";
import batchPrediction from "../static/images/batch_prediction.svg";
import icPlan from "../static/images/ic_plan.svg";
import disclaimer from "../static/images/help.svg";
import AccountSettings from '../components/AccountSettings';
import GeneralSettings from '../components/GeneralSettings';
import PracticeSettings from '../components/PracticeSettings';
// import SubscriptionSettings from '../components/SubscriptionSettings';
import Disclaimer from '../components/Disclaimer';
// import TranslationSettings from '../components/TranslationSettings';
import { Link } from "react-router-dom";

export class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSection: 'AccountSettings'
        }
    };

    componentDidMount() {
        document.body.classList.add('Instituation-body');
    }

    componentWillUnmount() {
        document.body.classList.remove('Instituation-body');
    }

    setSection = (section, event) => {
        event.preventDefault();
        this.setState({ currentSection: section });
        const offcanvasElement = document.getElementById('offcanvasExample');
        if (offcanvasElement) {
            const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
            if (offcanvas) {
                offcanvas.hide();
            }
        }
    }

    renderSection = () => {
        const alert = this.props.alert;
        const auth = this.props.auth;
        switch (this.state.currentSection) {
            case 'AccountSettings':
                return <AccountSettings auth={auth} alert={alert} />;
            case 'GeneralSettings':
                return <GeneralSettings auth={auth} alert={alert} />;
            // case 'SubscriptionSettings':
            //     return <SubscriptionSettings />;
            // case 'PracticeSettings':
            //     return <PracticeSettings auth={auth} alert={alert} />;
            case 'Disclaimer':
                return <Disclaimer auth={auth} alert={alert} />;
            // case 'TranslationSettings':
            //     return <TranslationSettings />;
            default:
                return <AccountSettings auth={auth} alert={alert} />;
        }
    }

    render() {
        return (
            <main className="Instituation-page dashboard-page right-to-left">
                <header className="Instituation-header d-none d-md-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <Link to='/student/dashboard'><img className="logo-img" src={logo} alt="image" /></Link>
                            </div>
                            <div className="col-6 text-end">
                                {/* Removed Jessie dropdown */}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="d-block d-md-none show__head ps-0 pe-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <Link to='/student/dashboard'><img className="logo-img" src={logo} alt="image" /></Link>
                            </div>
                            <div className="col-6 text-end">
                                <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <img src={menuBar} alt="image" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="account-settings">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5">
                                <div className="settings-div">
                                    <h2>Settings</h2>
                                    <p>Update and manage your account</p>
                                    <ul className="d-none d-md-block">
                                        <li>
                                            <a href="#" className={`menu-item ${this.state.currentSection === 'AccountSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('AccountSettings', e)}>
                                                <img src={applicationSettings} alt="image" />
                                                Account settings
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className={`menu-item ${this.state.currentSection === 'GeneralSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('GeneralSettings', e)}>
                                                <img src={batchPrediction} alt="image" />
                                                General settings
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className={`menu-item ${this.state.currentSection === 'SubscriptionSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('SubscriptionSettings', e)}>
                                                <img src={icPlan} alt="image" />
                                                Subscription Settings
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className={`menu-item ${this.state.currentSection === 'TranslationSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('TranslationSettings', e)}>
                                                <img src={batchPrediction} alt="image" />
                                                Translation Settings
                                            </a>
                                        </li> */}
                                        {/*<li>*/}
                                        {/*    <a href="#" className={`menu-item ${this.state.currentSection === 'PracticeSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('PracticeSettings', e)}>*/}
                                        {/*        <img src={icPlan} alt="image" />*/}
                                        {/*        Practice Settings*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href="#" className={`menu-item ${this.state.currentSection === 'Disclaimer' ? 'active' : ''}`} onClick={(e) => this.setSection('Disclaimer', e)}>
                                                <img src={disclaimer} alt="image" />
                                                Help
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                {this.renderSection()}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="offcanvas-main">
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
                            <button style={{ opacity: 0.8, color: "#898989", fontSize: "13px" }} type="button"
                                    className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"/>
                        </div>
                        <div className="offcanvas-body">
                            <div className="settings-div">
                                <ul>
                                    <li>
                                        <a href="#" className={`menu-item ${this.state.currentSection === 'AccountSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('AccountSettings', e)}>
                                            <img src={applicationSettings} alt="image" />
                                            Account settings
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className={`menu-item ${this.state.currentSection === 'GeneralSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('GeneralSettings', e)}>
                                            <img src={batchPrediction} alt="image" />
                                            General settings
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#" className={`menu-item ${this.state.currentSection === 'SubscriptionSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('SubscriptionSettings', e)}>
                                            <img src={icPlan} alt="image" />
                                            Subscription Settings
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className={`menu-item ${this.state.currentSection === 'TranslationSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('TranslationSettings', e)}>
                                            <img src={batchPrediction} alt="image" />
                                            Translation Settings
                                        </a>
                                    </li> */}
                                    {/*<li>*/}
                                    {/*    <a href="#" className={`menu-item ${this.state.currentSection === 'PracticeSettings' ? 'active' : ''}`} onClick={(e) => this.setSection('PracticeSettings', e)}>*/}
                                    {/*        <img src={icPlan} alt="image" />*/}
                                    {/*        Practice Settings*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a href="#" className={`menu-item ${this.state.currentSection === 'Disclaimer' ? 'active' : ''}`} onClick={(e) => this.setSection('Disclaimer', e)}>
                                            <img src={disclaimer} alt="image" />
                                            Help
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(AccountPage);
