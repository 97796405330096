import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API, VERSION } from "../routers/Urls";

class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.auth.user.email,
            password: ""
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const csrfToken = Cookies.get('csrftoken');
        const token = this.props.auth.token;
        const alert = this.props.alert;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${token}`
            }
        };
        const payload = {
            email: this.state.email,
            password: this.state.password
        };

        axios.patch(`${API}${VERSION}auth/user/update`, payload, config)
            .then(response => {
                const data = response.data;
                const messages = data.messages;
                alert.success(messages[0])
            })
            .catch(error => {
                const errors = error.response.data.errors;
                alert.error(errors[0]);
            });
    };

    render() {
        return (
            <div className="settings-box">
                <form onSubmit={this.handleSubmit}>
                    <h3>Account Settings</h3>
                    {/* <input type="text" name="name" placeholder="Your Name" value={this.state.name} onChange={this.handleChange} /> */}
                    <input type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                    <input type="password" name="password" placeholder="New password" value={this.state.password} onChange={this.handleChange} autoComplete="new-password" />
                    <button className="change__btn" type="submit">Save Changes</button>
                </form>
            </div>
        );
    }
}

export default AccountSettings;
