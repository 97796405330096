import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    SET_SELECTED_USER_CONFIGURATION
} from "../actions/types";

const initialState = {
    token: localStorage.getItem('token'),
    tokenExpiry: localStorage.getItem('tokenExpiry'),
    isAuthenticated: false,
    isLoading: false,
    user: null,
    selectedUserConfiguration: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('tokenExpiry', action.payload.expiry);
            return {
                ...state,
                token: action.payload.token,
                tokenExpiry: action.payload.expiry,
                user: action.payload.user,
                isAuthenticated: true,
                isLoading: false,
            };

        case SET_SELECTED_USER_CONFIGURATION:
            return {
                ...state,
                selectedUserConfiguration: action.payload
            };

        case LOGOUT_SUCCESS:
        case LOGIN_FAIL:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiry');
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                token: null,
                tokenExpiry: null,
                user: null,
                selectedUserConfiguration: null
            };
        default:
            return state;
    }
}
