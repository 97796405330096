import React, { Component } from 'react';
import speaker from "../static/images/speaker.svg";

class WordListModal extends Component {

    playAudio = (audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    };

    render() {
        const { words, loading } = this.props;

        return (
            <div className="modal main-modal main-modal4 fade" id="list-words" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-4">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-0 text-center flex-column">
                            <h5 className="modal-title mb-4 pb-1" id="staticBackdropLabel">List Words</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body border-0 p-0">
                            {loading ? (
                                <div className="loading-spinner-container d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="card___div">
                                    {words.map((word, index) => (
                                        <div key={word.order} className="row align-items-center">
                                            <div className="col-1">
                                                <h3>{index + 1}.</h3>
                                            </div>
                                            <div className="col-11">
                                                <div className="card__input">
                                                    <input type="text" name={`word-${index}`} value={word.term} placeholder="Word" required readOnly />
                                                    <button type="button" className="p-0 spkr__btn" onClick={() => this.playAudio(word.audioUrl)}>
                                                        <img src={speaker} alt="speaker icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {!loading && (
                            <div className="modal-footer justify-content-center modal-footer1 border-0 p-0">
                                <button style={{ width: '150px' }} className="m-0 btn-2nd" type="button" data-bs-dismiss="modal">
                                    Done
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default WordListModal;
