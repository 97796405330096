import React, { Component } from 'react';
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../static/images/logo.png";
import sound from "../static/images/sound.png";
import { getText } from "../utilities/getText";
import TestResultModal from "../components/TestResultModal";

export class TestPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            totalCount: 0,
            indexTerms: 0,
            remainingCount: 0,
            spelledTerms: [],
            testResults: [],
            terms: [],
            isCompleted: false,
            isModalShown: false,
        };
        this.modalRef = React.createRef();
        this.textInputRef = React.createRef();
    }

    componentDidMount() {
        const token = this.props.auth.token;
        const practiceSessionId = this.props.currentPracticeSession.id;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };
        const data = { practiceSessionId: practiceSessionId };

        axios.get(`${API}${VERSION}practice-sessions/test`, {params: data, ...config}).then(r => {
            const data = r.data;
            const terms = data.terms;
            const totalPracticeSessionTermCount = data.totalPracticeSessionTermCount;
            const count = totalPracticeSessionTermCount - terms.length;
            const remainingCount = terms.length;
            this.setState({ terms: terms, count: count, totalCount: totalPracticeSessionTermCount,
                    remainingCount: remainingCount},
                () => {
                this.updateTermCountAndProgressBar();
                if (terms.length > 0) {
                    this.playAudio(terms[0].audioUrl);
                }
            });
        }).catch(e => {
            const errors = e.response.data.errors;
            console.log('errors...');
        });

        // Set focus on the textarea when the component mounts
        if (this.textInputRef.current) {
            this.textInputRef.current.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.initTyping);
    }

    playAudio = (audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();

        // Set focus on the textarea when the component mounts
        if (this.textInputRef.current) {
            this.textInputRef.current.focus();
        }
    };

    showModal = () => {
        const modalElement = this.modalRef.current;
        if (modalElement) {
            this.modal = new window.bootstrap.Modal(modalElement, {
                backdrop: 'static',
                keyboard: false
            });
            this.modal.show();
            this.setState({ isModalShown: true }); // Set the modal as shown
        }
    }

    updateTermCountAndProgressBar = () => {
        const { remainingCount, totalCount } = this.state; // count is remaining terms
        if (totalCount > 0) {
            const completedTerms = totalCount - remainingCount;
            const progress = (completedTerms / totalCount) * 100;
            document.getElementById("progressBarFill").style.width = `${progress}%`;
        }
    };

    handleEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const { count, terms, indexTerms, spelledTerms, remainingCount } = this.state;
            const inputText = document.getElementById("textInput").value.trim().toLowerCase();

            if (inputText !== "") {
                // Update the state with the new count, indexTerms, and spelledTerms
                this.setState({
                    count: count + 1,
                    indexTerms: indexTerms + 1,
                    spelledTerms: [...spelledTerms, inputText],
                    remainingCount: remainingCount - 1
                }, () => {
                    if (this.state.indexTerms < terms.length) {
                        const nextAudioUrl = terms[this.state.indexTerms].audioUrl;
                        this.updateTermCountAndProgressBar();
                        this.playAudio(nextAudioUrl);
                    }
                });

                // Reset the input field
                document.getElementById("textInput").value = "";

                // Make API call to save the answer
                const token = this.props.auth.token;
                const sessionId = this.props.currentPracticeSession.id;
                const vocabId = terms[indexTerms].id;
                const payload = { "practiceSessionId": sessionId, "vocabId": vocabId, "yourSpelling": inputText };
                const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };
                axios.post(`${API}${VERSION}create-test-answer`, payload, config).then(r => {
                    const isCompleted = r.data.details.isCompleted;
                    if (isCompleted) {
                        document.getElementById("progressBarFill").style.width = '100%';
                        axios.get(`${API}${VERSION}retrieve-last-practice-session-answers`, config).then(r => {
                            const data = r.data;
                            this.setState({ testResults: data });
                            this.showModal();
                        }).catch(e => {
                            console.log('Unable to display the results.');
                        });
                    }
                }).catch(e => {
                    const errors = e.response.data.errors;
                });
            }
        }
    };

    handleKeyDown = (event) => {
        if (event.keyCode === 13 && !this.state.isModalShown) {
            this.handleEnter(event);
        }
    };

    redirectHome = () => {
        if (this.modal) {
            this.modal.hide();
        }
        this.props.history.push('/student/dashboard');
    }


    render() {
        const count = this.state.count;
        const totalCount = this.state.totalCount;
        const results = this.state.testResults;
        const isModalShown = this.state.isModalShown;
        return (
            <>
                <main className="Instituation-page dashboard-page right-to-left">
                    <header className="test-page-header">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <Link to='/student/dashboard'><img className="wdt-img" src={logo} alt="image" /></Link>
                                </div>
                                <div className="col-6 text-end"/>
                            </div>
                        </div>
                    </header>
                    <div className="container">
                        <h6>Type the word you hear</h6>
                        <br />
                        <div className="writenspeak-area">
                            <div className="content-box">
                                <div className="test-mode">
                                    <textarea
                                        id="textInput"
                                        onKeyDown={this.handleKeyDown}
                                        disabled={isModalShown} // Disable input if the modal is shown
                                        ref={this.textInputRef} // Reference to set focus
                                    />
                                    <div className="text__caret" id="caret" />
                                    <div id="termCount">{count} / {totalCount}</div>
                                    <div id="wrdct" />
                                </div>
                                <img
                                    src={sound}
                                    alt="sound icon"
                                    onClick={() => this.playAudio(this.state.terms[this.state.indexTerms].audioUrl)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div id="progressBarContainer">
                                <div id="progressBar">
                                    <div id="progressBarFill" />
                                </div>
                            </div>
                            <div className="practise-footer">
                                <button className="quit" onClick={() => this.props.history.push('/student/dashboard')}>
                                    Quit
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
                <TestResultModal results={results} ref={this.modalRef} redirectHome={this.redirectHome} />
            </>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    currentSessionVocab: state.vocab.currentSessionVocab,
    currentPracticeSession: state.vocab.currentPracticeSession
});

export default compose(connect(mapStateToProps), withAlert())(TestPage);
