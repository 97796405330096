import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withAlert} from "react-alert";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import logo from "../static/images/logo.png";
import {Link} from "react-router-dom";
import manSearch from "../static/images/man-search.svg";
import icEye from "../static/images/ic_eye.svg";
import Cookies from "js-cookie";
import {getText} from "../utilities/getText";


export class ResetPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step1: true,
            step2: false,
            step3: false,
            email: '',
            input1: '',
            input2: '',
            input3: '',
            input4: '',
            newPassword: '',
            isPasswordShown: false,
            isEyeDivShown: false,
        }
        this.formRef = React.createRef();
        this.inputs = [];
    };

    componentDidUpdate(prevProps, prevState) {
        // Check if 'step2' was false before and is true now
        if (!prevState.step2 && this.state.step2) {
            this.inputs = this.formRef.current.querySelectorAll('input');
            this.inputs.forEach((input, index) => {
                if (index === 0) {
                    input.addEventListener('paste', this.handlePaste);
                }
                input.addEventListener('keydown', this.handleKeyDown);
            });
        }
    }

    componentWillUnmount() {
        this.inputs.forEach((input, index) => {
            if (index === 0) {
                input.removeEventListener('paste', this.handlePaste);
            }
            input.removeEventListener('keydown', this.handleKeyDown);
        });
    }

    handleInput = (e) => {
        const input = e.target;
        const nextInput = input.nextElementSibling;
        if (nextInput && input.value) {
            nextInput.focus();
            if (nextInput.value) {
                nextInput.select();
            }
        }
    }

    handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        this.inputs.forEach((input, i) => {
            input.value = paste[i] || '';
        });
    }

    handleBackspace = (e) => {
        const input = e.target;
        if (input.value) {
            input.value = '';
            return;
        }
        const previousInput = input.previousElementSibling;
        if (previousInput) previousInput.focus();
    }

    handleArrowLeft = (e) => {
        const previousInput = e.target.previousElementSibling;
        if (previousInput) previousInput.focus();
    }

    handleArrowRight = (e) => {
        const nextInput = e.target.nextElementSibling;
        if (nextInput) nextInput.focus();
    }

    handleKeyDown = (e) => {
        const KEYBOARDS = {
            backspace: 8,
            arrowLeft: 37,
            arrowRight: 39,
        };

        switch (e.keyCode) {
            case KEYBOARDS.backspace:
                this.handleBackspace(e);
                break;
            case KEYBOARDS.arrowLeft:
                this.handleArrowLeft(e);
                break;
            case KEYBOARDS.arrowRight:
                this.handleArrowRight(e);
                break;
            default:
        }
    }

    /**
     * Create or generate a new verification number.
     * @param e
     */
    onClickSend = (e) => {
        e.preventDefault();
        const email = this.state.email;
        const alert = this.props.alert;
        const payload = {email: email}
        const csrfToken = Cookies.get('csrftoken');
        const config = {headers: {'Content-Type':'application/json', 'X-CSRFToken': csrfToken}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/create-verification-number`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.setState({step1: false, step2: true});
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    /**
     * Handles verifying if the owner if the email is indeed the owner.
     * @param e
     */
    onClickVerify = (e) => {
        e.preventDefault();
        const email = this.state.email;
        const alert = this.props.alert;
        const verificationNumber = this.getVerificationCode();
        const payload = {verificationNumber: verificationNumber, email: email}
        const csrfToken = Cookies.get('csrftoken');
        const config = {headers: {'Content-Type':'application/json', 'X-CSRFToken': csrfToken}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/verify-email`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.setState({step2: false, step3: true});
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    /**
     * Handles saving a new password.
     * @param e
     */
    onClickReset = (e) => {
        e.preventDefault();
        const newPassword = this.state.newPassword;
        const email = this.state.email;
        const alert = this.props.alert;
        const payload = {newPassword: newPassword, email: email}
        const csrfToken = Cookies.get('csrftoken');
        const config = {headers: {'Content-Type':'application/json', 'X-CSRFToken': csrfToken}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/reset`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.props.history.push('/login');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    onChangeEmail= (e) => {
        const email = e.target.value;
        this.setState({email});
    }

    onChangeNewPassword = (e) => {
        const newPassword = e.target.value;
        this.setState({newPassword});
    }

    showHide = () => {
        this.setState(prevState => ({
            isPasswordShown: !prevState.isPasswordShown,
            isEyeDivShown: !prevState.isEyeDivShown
        }));
    }

    onChangePassword = (e) => {
        const newPassword = e.target.value;
        this.setState({newPassword});
    }

    getVerificationCode = (e) =>  {
        const inputs = document.querySelectorAll('.d-flex.code__flex input');
        return Array.from(inputs).map(input => input.value).join('');
    }

    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const {step1, step2, step3, newPassword} = this.state;
        return (
            <div>
                <main className="right-to-left">
                    <section className="sign-in-area dashboard-page">
                        <div className="container">
                            <div className="sign-head verify-head">
                                <div className="d-flex align-items-center">
                                    <div className="col-6">
                                        <div className="clm-head">
                                            <Link to={'/login'}><img src={logo} alt="image" /></Link>
                                        </div>
                                    </div>
                                    <div className="col-6 d-none d-md-block" />
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="clm-1 d-none d-md-block">
                                    <div className="text-end">
                                        <img className="clm-img" src={manSearch} alt="image" />
                                    </div>
                                    <h2>{getText('ResetPasswordPage', 'welcomeMessage')}</h2>
                                </div>
                                {step1 && (
                                    <div className="clm-2">
                                        <div className="sign-in-form">
                                            <form className="sign__form verify__form" method="POST">
                                                <h2 className="m-0">{getText('ResetPasswordPage', 'verifyIdentity')}</h2>
                                                <span>{getText('ResetPasswordPage', 'emailPrompt')}</span>
                                                <div className="group_inp">
                                                    <input type="email" onChange={this.onChangeEmail} placeholder={getText('ResetPasswordPage', 'emailPlaceholder')} required />
                                                </div>
                                                <button className="ms-auto mt-3" type="submit" onClick={this.onClickSend}>
                                                    {getText('ResetPasswordPage', 'sendCodeButton')}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                {step2 && (
                                    <div className="clm-2">
                                        <div className="sign-in-form">
                                            <form ref={this.formRef} onInput={this.handleInput}
                                                  className="sign__form verify__form" method="POST">
                                                <h2 className="m-0">{getText('ResetPasswordPage', 'enterCode')}</h2>
                                                <span className="mt-2 pt-1">
                                                    {getText('ResetPasswordPage', 'codePrompt')} {this.state.email}
                                                </span>
                                                <div className="d-flex code__flex">
                                                    <input type="tel" maxLength="1" pattern="[0-9]"
                                                           className="form-control"
                                                           name="codeInput1" />
                                                    <input type="tel" maxLength="1" pattern="[0-9]"
                                                           className="form-control"
                                                           name="codeInput2" />
                                                    <input type="tel" maxLength="1" pattern="[0-9]"
                                                           className="form-control"
                                                           name="codeInput3" />
                                                    <input type="tel" maxLength="1" pattern="[0-9]"
                                                           className="form-control"
                                                           name="codeInput4" />
                                                </div>
                                                <button className="ms-auto mt-3 overwrite" type="submit" onClick={this.onClickVerify}>
                                                    {getText('ResetPasswordPage', 'verifyButton')}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                {step3 && (
                                    <div className="clm-2">
                                        <div className="sign-in-form">
                                            <form className="sign__form verify__form" action="#" method="POST">
                                                <h2 className="m-0">{getText('ResetPasswordPage', 'resetPassword')}</h2>
                                                <span className="mt-2 pt-1">{getText('ResetPasswordPage', 'resetPrompt')}</span>
                                                <div className="group_inp mb-5">
                                                    <input type={this.state.isPasswordShown ? "text" : "password"}
                                                           placeholder={getText('ResetPasswordPage', 'passwordPlaceholder')} value={newPassword} onChange={this.onChangePassword} />
                                                    <div className={`eye-div ${this.state.isEyeDivShown ? "eye-div-show" : ""}`}
                                                         onClick={this.showHide}>
                                                        <img src={icEye} alt="image" />
                                                    </div>
                                                </div>
                                                <button type="submit" onClick={this.onClickReset}>{getText('ResetPasswordPage', 'saveButton')}</button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(ResetPasswordPage);
