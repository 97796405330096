import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import axios from 'axios';
import store from './store/configureStore';
import AppRouter from './routers/AppRouter';
import LoadingPage from './pages/LoadingPage';
import { loadLanguage } from './utilities/loadLanguage';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import './styles/styles.scss';
import {LOGOUT_SUCCESS} from "./actions/types";

// optional alert configuration
const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    transition: transitions.SCALE
};

// set up the base endpoint URL
axios.defaults.baseURL = '/';


// Redirect to login if the token has expired
axios.interceptors.response.use(
    (response) => {
        return response; // Return the response if successful
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Dispatch the LOGOUT_SUCCESS action to clear state and localStorage
            store.dispatch({ type: LOGOUT_SUCCESS });

            // Redirect to the login page
            window.location.href = '/login';
        }
        return Promise.reject(error); // Always reject the error
    }
);

// Utility function to get the browser language
const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage || 'en';
    return language.split('-')[0]; // Return the language code (e.g., 'en', 'es')
};

// show a loading page
ReactDOM.render(<LoadingPage />, document.getElementById('app'));

// Dispatch the loadLanguage thunk to load the language data
const browserLanguage = getBrowserLanguage();
store.dispatch(loadLanguage(browserLanguage)).then(() => {
    // Once the language data is loaded, render the main application
    ReactDOM.render(
        <Provider store={store}>
            <AlertProvider template={AlertTemplate} {...options}>
                <AppRouter />
            </AlertProvider>
        </Provider>,
        document.getElementById('app')
    );
});
