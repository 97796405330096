// Disclaimer.js
import React from 'react';

const Disclaimer = () => (
    <div className="settings-box">
        <form action="#" method="POST">
            <h3>Help</h3>
            <p>If you have any questions about Fluent Lingo, how to use it, or if you would like more categories or languages to be added, or if you're experiencing a bug that you would like to report, feel free to email us at <a href="mailto:support@fluentlingo.com">support@fluentlingo.com</a>.</p>
        </form>
    </div>
);

export default Disclaimer;
