import React, { Component } from 'react';
import arrowDropDown1 from "../static/images/arrow_drop_down1.svg";
import markImg from "../static/images/mark-img.svg";
import sound from "../static/images/sound.png";
import rightCheckIcon from '../static/images/right-check.svg';
import closeCheckIcon from '../static/images/close-check.svg';
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

class ResultModal extends Component {
    state = {
        showDetailedView: false,
        selectedResult: null,
        loading: false,
        resultDetails: []
    };

    componentDidMount() {
        const modalElement = document.getElementById('results-modal');
        modalElement.addEventListener('hidden.bs.modal', this.handleModalClose);
    }

    componentWillUnmount() {
        const modalElement = document.getElementById('results-modal');
        modalElement.removeEventListener('hidden.bs.modal', this.handleModalClose);
    }

    handleModalClose = () => {
        this.setState({
            showDetailedView: false,
            selectedResult: null,
            loading: false,
            resultDetails: []
        });
    };

    playAudio = (audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    };

    getGradeColor = (grade) => {
        if (grade >= 80) {
            return '#00AEA4';
        } else if (grade >= 60) {
            return '#FFC577';
        } else {
            return '#FF4E4E';
        }
    };

    handleAttemptClick = (result) => {
        this.setState({ loading: true, selectedResult: result, showDetailedView: true }, () => {
            const resultId = result.id;
            const token = this.props.auth.token;
            const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

            axios.get(`${API}${VERSION}results/${resultId}/answers`, config)
                .then(response => {
                    setTimeout(() => {
                        this.setState({ resultDetails: response.data, loading: false });
                    }, 500);
                })
                .catch(error => {
                    const errors = error.response?.data?.errors;
                    if (errors && errors.length > 0) {
                        this.props.alert.error(errors[0]);
                    }
                    this.setState({ loading: false });
                });
        });
    };

    handleBackClick = () => {
        this.setState({ showDetailedView: false, selectedResult: null, resultDetails: [] });
    };

    getGradeIcon = (isRight) => {
        return isRight ? rightCheckIcon : closeCheckIcon;
    };

    render() {
        const { results } = this.props;
        const { showDetailedView, resultDetails, loading } = this.state;
        return (
            <div>
                {results.length > 0 ? (
                    <div className="modal main-modal main-modal3 fade" id="results-modal" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-3">
                            <div className="modal-content">
                                <form action="#" method="POST">
                                    <div className="modal-header border-0 p-0 text-center flex-column">
                                        <h5 className="modal-title mb-2" id="staticBackdropLabel">Results</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body border-0 p-0">
                                        {loading ? (
                                            <div className="loading-spinner-container d-flex justify-content-center align-items-center" style={{ height: '350px' }}>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            !showDetailedView ? (
                                                <div className="attempt-area">
                                                    {results.map(result => (
                                                        <div key={result.id} className="attempt-div" onClick={() => this.handleAttemptClick(result)}>
                                                            <h3>{result.attempt}</h3>
                                                            <div className="inr-attempt">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p>Date:<span>{new Date(result.createdAt).toLocaleDateString()}</span></p>
                                                                    <div className="d-flex wdt-sm-div align-items-center">
                                                                        <p>Grade</p>
                                                                        <div className="progress__div">
                                                                            <div style={{ background: this.getGradeColor(result.grade), width: `${result.grade}%` }} className="progress-bar" />
                                                                        </div>
                                                                        <span className="m-0">{result.grade}%</span>
                                                                    </div>
                                                                    <button type="button"><img src={arrowDropDown1} alt="arrow icon" /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="modal-content" style={{ border: 'none', minHeight: '350px' }}>
                                                    <form action="#" method="POST">
                                                        <div className="modal-body">
                                                            <div className="spelling-div">
                                                                <table className="w-100">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Your Spelling</th>
                                                                        <th>Correct Spelling</th>
                                                                        <th className="text-end">Results</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {resultDetails.map((detail, index) => (
                                                                        <tr key={index}>
                                                                            <td className="gray-alex">{detail.yourSpelling}</td>
                                                                            <td className="alex-td">
                                                                                <img
                                                                                    src={sound}
                                                                                    alt="sound icon"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => this.playAudio(detail.audioUrl)}
                                                                                />
                                                                                {detail.correctSpelling}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <img src={this.getGradeIcon(detail.isRight)} alt="grade" />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer justify-content-between modal-footer1">
                                                            <button type="button" className="m-0 btn-2nd" onClick={this.handleBackClick}>Back</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="modal-footer d-flex justify-content-between modal-footer1 border-0 p-0" />
                                </form>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="modal main-modal result-quizz main-modal3 fade" id="results-modal" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-3">
                            <div className="modal-content">
                                <form action="#" method="POST">
                                    <div className="modal-header border-0 p-0 text-center flex-column">
                                        <h5 className="modal-title mb-2" id="staticBackdropLabel">Results</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body border-0 p-0">
                                        <div className="quizz-area text-center">
                                            <img src={markImg} alt="mark icon" />
                                            <p>No completed quizzes yet</p>
                                        </div>
                                    </div>
                                    <div className="modal-footer d-flex justify-content-between modal-footer1 border-0 p-0" />
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(ResultModal);
