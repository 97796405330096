import { connect } from "react-redux";
import {Redirect, Route} from "react-router-dom";
import React from "react";

export const PublicRoute = ({isAuthenticated, component: Component, ...rest}) => (
    <Route {...rest} component={(props) => (
        isAuthenticated ? (
            <Redirect to="/student/dashboard" />
        ) : (
             <Component {...props} />
        )
    )} />
);

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps)(PublicRoute);
