import React, { Component } from 'react';
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import logo from "../static/images/logo.png";
import sound from "../static/images/sound.png";
import speaker from "../static/images/speaker2.png"
import { Link } from "react-router-dom";


class PracticePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            characterIndex: 0,
            errors: 0,
            isTyping: false,
            paragraphs: [],
            currentParagraph: [],
            activeTerm: '',
            selectedParagraphIndex: 0,
            isDisabled: true,
            termsPracticed: [],
            isPracticeComplete: false,
            selectedTerm: null,
            selectedTermType: null
        };
    }

    componentDidMount() {
        const { currentPracticeSession, practicePattern } = this.props;
        const currentCharIndex = currentPracticeSession.currentCharIndex;
        const currentSentenceIndex = currentPracticeSession.currentSentenceIndex;
        const isPracticeCompleted = currentPracticeSession.isPracticeCompleted;
        const currentParagraph = this.createCharacterObjects(currentSentenceIndex, currentCharIndex);
        const currentCharIndexAudio = currentParagraph[currentCharIndex]?.audio;

        if (isPracticeCompleted) {
            this.setState({ isDisabled: false });
        }

        this.setState({
            paragraphs: practicePattern,
            selectedParagraphIndex: currentSentenceIndex,
            characterIndex: currentCharIndex,
            currentParagraph: currentParagraph
        }, () => {
            document.addEventListener('keydown', this.initTyping);
            this.updateProgressBar();
            if (currentCharIndexAudio) {
                this.playAudio(currentCharIndexAudio);
            }
        });

        // Listen for the beforeunload event
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        // Listen for navigation changes within the app
        this.unlisten = this.props.history.listen(() => {
            this.saveProgress();
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.initTyping);

        // Remove the beforeunload event listener
        window.removeEventListener('beforeunload', this.handleBeforeUnload);

        // Remove the navigation listener
        if (this.unlisten) {
            this.unlisten();
        }
    }

    playAudio = (audio) => {
        const { audioUrl } = audio;
        const audioElement = new Audio(audioUrl);
        audioElement.play();
    };

    handleBeforeUnload = (event) => {
        this.saveProgress();
    };

    createCharacterObjects = (selectedParagraphIndex, characterIndex) => {
        const { practicePattern, currentSessionVocab } = this.props;

        // Get the current practice pattern item
        const currentPattern = practicePattern[selectedParagraphIndex];

        if (!currentPattern) {
            console.error('No matching practice pattern found.');
            return [];
        }

        let charIndex = 0;
        const characters = [];

        if (currentPattern.type === 'mixed') {
            // Handle 'mixed' type by iterating over termIds
            currentPattern.termIds.forEach((termMapping) => {
                const [termId, term] = Object.entries(termMapping)[0];
                const vocabItem = currentSessionVocab.find(item => item.id === parseInt(termId, 10));

                if (vocabItem) {
                    let audioData = { audioUrl: null };

                    // Always use segments for mixed type if available
                    const termSegments = vocabItem.audio?.termSegments?.audioUrls;
                    const words = term.split(' ');

                    words.forEach((word, wordIndex) => {
                        if (termSegments && termSegments.length > 0) {
                            // Correctly map each word to its segment
                            audioData = { audioUrl: termSegments[wordIndex] };
                        } else {
                            audioData = { audioUrl: vocabItem.audio?.term?.audioUrl };
                        }

                        // Generate characters for each letter in the word
                        word.split("").forEach((char) => {
                            characters.push({
                                char,
                                class: charIndex < characterIndex ? 'correct' : (charIndex === characterIndex ? 'active' : ''),
                                termId: parseInt(termId, 10),
                                type: 'term',
                                audio: {
                                    audioUrl: audioData.audioUrl
                                }
                            });
                            charIndex++;
                        });

                        // Add a space character after each word with the same audio
                        characters.push({
                            char: ' ',
                            class: charIndex < characterIndex ? 'correct' : (charIndex === characterIndex ? 'active' : ''),
                            termId: parseInt(termId, 10),
                            type: 'term',
                            audio: {
                                audioUrl: audioData.audioUrl
                            }
                        });
                        charIndex++;
                    });
                }
            });

        } else {
            // Handle 'term' and 'sentence' types by splitting text
            const terms = currentPattern.text.split(' ');

            terms.forEach((term, index) => {
                let termId = currentPattern.termId;
                let audioData = { audioUrl: null };

                // Find the matching vocab item
                const vocabItem = currentSessionVocab.find(item => item.id === termId);
                if (vocabItem) {
                    if (currentPattern.type === 'term') {
                        const termSegments = vocabItem.audio?.termSegments?.audioUrls;
                        if (termSegments && termSegments.length > 0) {
                            const segmentIndex = index % termSegments.length;
                            audioData = { audioUrl: termSegments[segmentIndex] };
                        } else {
                            audioData = { audioUrl: vocabItem.audio?.term?.audioUrl };
                        }
                    } else if (currentPattern.type === 'sentence') {
                        const primaryExampleSegments = vocabItem.audio?.primaryExampleSegments?.audioUrls;
                        if (primaryExampleSegments && primaryExampleSegments.length > 0) {
                            const segmentIndex = index % primaryExampleSegments.length;
                            audioData = { audioUrl: primaryExampleSegments[segmentIndex] };
                        }
                    }
                }

                // Generate characters for each letter in the term
                term.split("").forEach((char) => {
                    characters.push({
                        char,
                        class: charIndex < characterIndex ? 'correct' : (charIndex === characterIndex ? 'active' : ''),
                        termId: termId,
                        type: currentPattern.type === 'sentence' ? 'sentence' : 'term',
                        audio: {
                            audioUrl: audioData.audioUrl
                        }
                    });
                    charIndex++;
                });

                // Add a space character after each term with the same audio
                characters.push({
                    char: ' ',
                    class: charIndex < characterIndex ? 'correct' : (charIndex === characterIndex ? 'active' : ''),
                    termId: termId,
                    type: currentPattern.type === 'sentence' ? 'sentence' : 'term',
                    audio: {
                        audioUrl: audioData.audioUrl
                    }
                });
                charIndex++;
            });
        }

        // Remove the last element (space) since it was added after the last word
        if (characters.length > 0 && characters[characters.length - 1].char === ' ') {
            characters.pop();
        }

        return characters;
    };

    nextParagraph = () => {
        const { paragraphs, selectedParagraphIndex, termsPracticed } = this.state;
        const { currentSessionVocab } = this.props;
        const incrementedSelectedParagraphIndex = selectedParagraphIndex + 1;

        if (incrementedSelectedParagraphIndex >= paragraphs.length) {
            this.updateProgressBar(true);
            this.setState({
                isDisabled: false,
                characterIndex: 0,
                selectedParagraphIndex: 0,
                isPracticeComplete: true
            });
            // Handle the last term case
            const lastTermIndex = Math.floor(selectedParagraphIndex / 2);
            if (currentSessionVocab[lastTermIndex]) {
                const lastTerm = currentSessionVocab[lastTermIndex].term;
                if (!termsPracticed.includes(lastTerm)) {
                    this.setState(prevState => ({
                        termsPracticed: [...prevState.termsPracticed, lastTerm]
                    }));
                }
            }
            return;
        }

        const selectedParagraph = this.createCharacterObjects(incrementedSelectedParagraphIndex, 0);
        const audio = selectedParagraph[0].audio;

        this.setState({
            currentParagraph: selectedParagraph,
            selectedParagraphIndex: incrementedSelectedParagraphIndex,
            characterIndex: 0,
            isTyping: false,
            errors: 0
        }, () => {
            this.updateProgressBar();
            this.playAudio(audio);
        });

        // Check if the user has practiced a term
        if (incrementedSelectedParagraphIndex % 2 === 0) {
            const termIndex = (incrementedSelectedParagraphIndex / 2) - 1;
            if (currentSessionVocab[termIndex]) {
                const term = currentSessionVocab[termIndex].term;
                if (!termsPracticed.includes(term)) {
                    this.setState(prevState => ({
                        termsPracticed: [...prevState.termsPracticed, term]
                    }));
                }
            }
        }
    };

    saveProgress = () => {
        const token = this.props.auth.token;
        const sessionId = this.props.currentPracticeSession.id;
        const currentCharIndex = this.state.characterIndex;
        const currentSentenceIndex = this.state.selectedParagraphIndex;
        const termsPracticed = this.state.termsPracticed;
        const payload = { "currentCharIndex": currentCharIndex, "currentSentenceIndex": currentSentenceIndex,
                          "termsPracticed": termsPracticed };
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };
        axios.patch(`${API}${VERSION}update-practice-session/${sessionId}/progress`, payload, config).then(r => {
            const data = r.data;
            // Todo: Possible improvement ==> Update the Redux global state for total practice.
        }).catch(e => {
            console.log('Error saving progress: ', e.response.data.errors);
        });
    }

    completedPractice = () => {
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const sessionId = this.props.currentPracticeSession.id;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };
        axios.patch(`${API}${VERSION}practice-session/${sessionId}/complete`, null, config).then(r => {
            this.saveProgress();
            this.props.history.push('/quiz');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    updateProgressBar = (isComplete = false) => {
        const { selectedParagraphIndex, paragraphs } = this.state;
        const paragraphLen = paragraphs.length;

        let progressPercentage;
        if (isComplete) {
            progressPercentage = 100;
        } else {
            progressPercentage = ((selectedParagraphIndex / (paragraphLen)) * 100);
        }

        document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
    };

    initTyping = (event) => {
        const { currentParagraph, characterIndex, errors, isPracticeComplete } = this.state;

        // Prevent further typing if practice is complete
        if (isPracticeComplete) return;

        const characters = currentParagraph;

        if (event.key === 'Backspace') {
            event.preventDefault();

            if (characterIndex > 0) {
                const newIndex = characterIndex - 1;
                if (characters[newIndex].class === 'incorrect') {
                    this.setState({ errors: errors - 1 });
                }

                characters[newIndex].class = '';
                characters[characterIndex].class = '';
                characters[newIndex].class = 'active';

                this.setState({ characterIndex: newIndex, currentParagraph: characters });
            }
        } else {
            const typedCharacter = event.key;
            if (characterIndex < characters.length) {
                if (typedCharacter === characters[characterIndex].char) {
                    characters[characterIndex].class = 'correct';

                    const newIndex = characterIndex + 1;
                    characters[newIndex] && (characters[newIndex].class = 'active');

                    this.setState({ characterIndex: newIndex, currentParagraph: characters });

                    if (characters[characterIndex].char === ' ') {
                        const audio = characters[newIndex].audio;
                        this.playAudio(audio);
                    }


                    if (characters.length === newIndex) {
                        this.nextParagraph();
                    }

                } else {
                    this.setState({ errors: errors + 1 });
                    // I decided not to add the incorrect class. It looks better without the red color.
                    characters[characterIndex].class = 'active';
                }

            } else {
                this.nextParagraph();
            }
        }
    };

    resetGame = () => {
        this.setState({ isTyping: false });
        this.nextParagraph();
        document.querySelector('.progress-bar').style.width = '0%';
        this.props.history.push('/student/dashboard');
    };

    handleClick = (id, selectedTermType) => {
        const selectedTerm = this.props.currentSessionVocab.find(termObj => termObj.id === id);
        this.setState({ selectedTerm, selectedTermType });
        document.getElementById('ws-wdbox').style.display = 'block';
    };

    handleClose = () => {
        document.getElementById('ws-wdbox').style.display = 'none';
    }

    playAudioPopUp = (popUpSelection) => {
        const selectedTerm = this.state.selectedTerm;
        const selectedTermType = this.state.selectedTermType;
        let audioUrl;
        if (selectedTermType === "term") {
            if (popUpSelection === "term") {
                audioUrl = selectedTerm.audio.term.audioUrl;
            } else if (popUpSelection === "primaryDefinition") {
                audioUrl = selectedTerm.audio.primaryDefinition.audioUrl;
            } else {
                audioUrl = selectedTerm.audio.primaryExample.audioUrl;
            }

        }

        if (audioUrl) {
            const audioElement = new Audio(audioUrl);
            audioElement.play();
        }
    }


    completeQuiz = () => {
        this.completedPractice();
    }

    playCurrentTerm = () => {
        const currentParagraph = this.state.currentParagraph;
        const characterIndex = this.state.characterIndex;
        const audio = currentParagraph[characterIndex].audio;
        const isPracticeComplete = this.state.isPracticeComplete;
        if (!isPracticeComplete) {
            this.playAudio(audio);
        }
    }

    render() {
        const { currentParagraph, isDisabled, isPracticeComplete, selectedTerm, selectedTermType } = this.state;
        const language = this.props.auth.selectedUserConfiguration.language.name || "English";
        return (
            <main className="Instituation-page dashboard-page right-to-left">
                <header className="Instituation-header">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <Link to='/student/dashboard'><img className="wdt-img" src={logo} alt="image" /></Link>
                            </div>
                            <div className="col-6 text-end"/>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <div className="writenspeak-area">
                        <div className="content-box">
                            <input type="text" className="input-field" id="txt" onClick={() => this.initTyping()} />
                            <div className="text-of-typing">
                                <p id="terms-js" className={isPracticeComplete ? 'hide-cursor' : ''}>
                                    {currentParagraph.map((span, index) => (
                                        <span  style={{ cursor: 'pointer' }} onClick={() => this.handleClick(span.termId, span.type)} key={index} className={span.class}>{span.char}</span>
                                    ))}
                                </p>

                                <div className="ws-wordbox langbox" id="ws-wdbox">
                                    <div className="ws-inner">
                                        <i className="fas fa-close close-ws" onClick={this.handleClose} style={{ cursor: 'pointer' }} />
                                        {selectedTerm && (
                                            <>
                                                {selectedTermType === "term" ? (
                                                    <>
                                                        <h4>
                                                          <span>
                                                            <span>
                                                                <img src={speaker} alt="image" onClick={_ => this.playAudioPopUp('term')}/>
                                                            </span>
                                                              {selectedTerm.term}
                                                          </span>
                                                            <span className="wslang-option">
                                                            <span className="spech">{selectedTerm.partOfSpeech}</span>
                                                            <span className="langs">{language}</span>
                                                          </span>
                                                        </h4>
                                                        <p>Definition: {selectedTerm.primaryDefinition}
                                                        <span>
                                                            <img src={speaker} alt="image"
                                                                 onClick={_ => this.playAudioPopUp('primaryDefinition')} />
                                                        </span>
                                                        <br/>
                                                        Example: {selectedTerm.primaryExample}
                                                        <span>
                                                            <img src={speaker} alt="image"
                                                                 onClick={_ => this.playAudioPopUp('primaryExample')} />
                                                        </span>
                                                        </p>

                                                    </>
                                                ) : (
                                                    <>
                                                        <h4>
                                                            <span className="langs">{language}</span>
                                                        </h4>
                                                        <p className="listen-p">
                                                            {/*A <span className="active">computer</span> program designed to*/}
                                                            {/*simulate conversation with human users, especially over the*/}
                                                            {/*internet*/}
                                                            Sentence: {selectedTerm.primaryExample}
                                                        </p>
                                                        <audio
                                                            key={selectedTerm.audio.primaryExample.audioUrl}
                                                            controls>
                                                            <source
                                                                src={selectedTerm.audio.primaryExample.audioUrl}
                                                                type="audio/mpeg">
                                                            </source>
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <img src={sound} alt="image"  style={{ cursor: 'pointer' }} onClick={this.playCurrentTerm} />
                        </div>
                        <div className="progress-area">
                            <div className="progress-bar" />
                        </div>
                        <div className="practise-footer">
                            <button className="quit" onClick={this.resetGame}>
                                Quit
                            </button>
                            <button id="completeqz" disabled={isDisabled} onClick={this.completeQuiz}>
                                Complete Quiz
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    currentSessionVocab: state.vocab.currentSessionVocab,
    currentPracticeSession: state.vocab.currentPracticeSession,
    practicePattern: state.vocab.practicePattern
});

export default compose(connect(mapStateToProps), withAlert())(PracticePage);
