import React, {Component} from 'react';
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import {LOGIN_SUCCESS} from "../actions/types";
import {compose} from "redux";
import {connect} from "react-redux";
import {withAlert} from "react-alert";
import {Link} from "react-router-dom";
import logo from "../static/images/logo.png";
import clmImag from "../static/images/clm-img.svg";
import icEye from "../static/images/ic_eye.svg";
import Cookies from "js-cookie";
import {getText} from "../utilities/getText";


export class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isPasswordShown: false,
            isEyeDivShown: false
        }
    };

    onClickLogin = (e) => {
        e.preventDefault();
        const password = this.state.password;
        const email = this.state.email;
        const alert = this.props.alert;
        const payload = {password: password, username: email}
        const csrfToken = Cookies.get('csrftoken');
        const config = {headers: {'Content-Type':'application/json', 'X-CSRFToken': csrfToken}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/login`, body, config).then(r => {
            const data = r.data
            alert.show(data.messages[0]);
            this.props.dispatch({type: LOGIN_SUCCESS, payload:data})
            this.props.history.push('/student/dashboard');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    onChangePassword = (e) => {
        const password = e.target.value;
        this.setState({password});
    }

    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({email});
    }

    showHide = () => {
        this.setState(prevState => ({
            isPasswordShown: !prevState.isPasswordShown,
            isEyeDivShown: !prevState.isEyeDivShown
        }));
    }


    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const password = this.state.password;
        return (
            <main className="right-to-left">
                <section className="sign-in-area dashboard-page">
                    <div className="container">
                        <div className="sign-head">
                            <div className="d-flex align-items-center">
                                <div className="col-6">
                                    <div className="clm-head">
                                        <Link to='/login'><img src={logo} alt="image"/></Link>
                                    </div>
                                </div>
                                <div className="col-6 d-none d-md-block">
                                    <div className="simple-text text-end">
                                        <p>{getText('LoginPage', 'dontHaveAccount')} <Link to='/register'>{getText('LoginPage', 'register')}</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="clm-1 d-none d-md-block">
                                <div className="text-end">
                                    <img className="clm-img" src={clmImag} alt="image"/>
                                </div>
                                <h2>{getText('LoginPage', 'welcomeMessage')}</h2>
                            </div>
                            <div className="clm-2">
                                <div className="sign-in-form">
                                    <form className="sign__form" method="POST" onSubmit={this.onClickLogin}>
                                        <h2>{getText('LoginPage', 'signIn')}</h2>
                                        <p className="d-block d-md-none">
                                            {getText('LoginPage', 'welcomeMessage')}
                                        </p>
                                        <div className="group_inp">
                                            <input type="email" placeholder={getText('LoginPage', 'emailPlaceholder')}
                                                   onChange={this.onChangeEmail} />
                                        </div>
                                        <div className="group_inp">
                                            <input type={this.state.isPasswordShown ? "text" : "password"}
                                                   placeholder={getText('LoginPage', 'passwordPlaceholder')} value={password} onChange={this.onChangePassword} />
                                            <div className={`eye-div ${this.state.isEyeDivShown ? "eye-div-show" : ""}`}
                                                 onClick={this.showHide}>
                                                <img src={icEye} alt="image"/>
                                            </div>
                                        </div>
                                        <div className="group_inp">
                                            <Link to='/reset-password'>{getText('LoginPage', 'forgotPassword')}</Link>
                                        </div>
                                        <div className="simple-text d-block d-md-none">
                                            <p>{getText('LoginPage', 'dontHaveAccount')} <Link to='/register'>{getText('LoginPage', 'register')}</Link></p>
                                        </div>
                                        <button type="submit">{getText('LoginPage', 'signInButton')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(LoginPage);
