import React, { Component } from 'react';
import sound from "../static/images/sound.png";
import { API, VERSION } from "../routers/Urls";
import Cookies from "js-cookie";
import axios from "axios";

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            languageId: '',
            languageName: '',
            accents: [],
            accentId: '',
            accentName: '',
            voices: [],
            voiceId: '',
            voiceName: '',
            bucketName: '',
            loading: true  // Add loading state
        };
        this.audio = null; // Track the audio instance
    }

    componentDidMount() {
        this.fetchLanguagesAndUserSelection();
    }

    componentWillUnmount() {
        // Stop the audio if it's playing when the component unmounts
        if (this.audio) {
            this.audio.pause();
            this.audio = null;
        }
    }

    fetchLanguagesAndUserSelection = () => {
        const csrfToken = Cookies.get('csrftoken');
        const token = this.props.auth.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${token}`
            }
        };

        axios.get(`${API}${VERSION}languages/accents-voices`, config).then(response => {
            const { languages, userSelection, bucketName } = response.data;
            const selectedLanguageData = languages.find(lang => lang.id === userSelection.selectedLanguage) || {};
            const accents = selectedLanguageData.accents || [];
            const selectedAccentData = accents.find(acc => acc.id === userSelection.selectedAccent) || {};
            const voices = selectedAccentData.voices || [];

            this.setState({
                languages,
                languageId: userSelection.selectedLanguage || '',
                languageName: selectedLanguageData.name || '',
                accents,
                accentId: userSelection.selectedAccent || '',
                accentName: selectedAccentData.name || '',
                voices,
                voiceId: userSelection.selectedVoice || '',
                voiceName: voices.find(voice => voice.id === userSelection.selectedVoice)?.name || '',
                bucketName: bucketName,
                loading: false  // Set loading to false after data is fetched
            });
        }).catch(error => {
            console.error("There was an error fetching the languages!", error);
            this.setState({ loading: false });  // Set loading to false in case of error
        });
    }

    handleLanguageChange = (e) => {
        const languageId = e.target.value;
        const selectedLanguageData = this.state.languages.find(lang => lang.id === parseInt(languageId));
        const accents = selectedLanguageData ? selectedLanguageData.accents : [];
        const accentName = accents.length ? accents[0].name : '';
        const voices = accents.length ? accents[0].voices : [];
        const voiceName = voices.length ? voices[0].name : '';
        this.setState({
            languageId,
            languageName: selectedLanguageData ? selectedLanguageData.name : '',
            accents,
            accentId: accents.length ? accents[0].id : '',
            accentName,
            voices,
            voiceId: voices.length ? voices[0].id : '',
            voiceName
        });
    }

    handleAccentChange = (e) => {
        const accentId = e.target.value;
        const selectedAccentData = this.state.accents.find(acc => acc.id === parseInt(accentId));
        const voices = selectedAccentData ? selectedAccentData.voices : [];
        const voiceName = voices.length ? voices[0].name : '';
        this.setState({
            accentId,
            accentName: selectedAccentData ? selectedAccentData.name : '',
            voices,
            voiceId: voices.length ? voices[0].id : '',
            voiceName
        });
    }

    handleVoiceChange = (e) => {
        const voiceId = e.target.value;
        const selectedVoiceData = this.state.voices.find(voice => voice.id === parseInt(voiceId));
        this.setState({
            voiceId,
            voiceName: selectedVoiceData ? selectedVoiceData.name : ''
        });
    }

    generateS3Url = () => {
        const { languageName, accentName, voiceName, bucketName } = this.state;
        const base = `${bucketName}.s3.us-west-1.amazonaws.com`;
        return `https://${base}/${languageName}/${accentName}/${voiceName}/sample_test.mp3`;
    }

    playAudio = () => {
        const audioUrl = this.generateS3Url();

        // Pause the current audio if it's playing
        if (this.audio) {
            this.audio.pause();
            this.audio = null;
        }

        // Play the new audio
        this.audio = new Audio(audioUrl);
        this.audio.play();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const csrfToken = Cookies.get('csrftoken');
        const token = this.props.auth.token;
        const { languageId, accentId, voiceId } = this.state;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${token}`
            }
        };

        const data = {
            languageId: parseInt(languageId),
            accentId: parseInt(accentId),
            voiceId: parseInt(voiceId)
        };

        axios.post(`${API}${VERSION}update-user-configuration`, data, config).then(response => {
            const message = response.data.messages[0];
            this.props.alert.success(message);
        }).catch(error => {
            console.error("There was an error saving the configuration!", error);
        });
    }

    render() {
        const { languages, accents, voices, languageId, languageName, accentId, accentName, voiceId, voiceName, loading } = this.state;

        if (loading) {
            // Display loading spinner while loading is true
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div className="settings-box settings-box2">
                <form onSubmit={this.handleSubmit}>
                    <h3>General Settings</h3>

                    <h5>Language</h5>
                    <p>
                        Choose the language you’d like to practice or improve on. Your
                        <br className="d-none d-lg-block" /> current language is set to: {languageName}
                    </p>
                    <select value={languageId} onChange={this.handleLanguageChange}>
                        {languages.map(language => (
                            <option key={language.id} value={language.id}>{language.name}</option>
                        ))}
                    </select>
                    <br/>

                    <h5>Accent</h5>
                    <p>
                        Choose the pronunciation of the words in the selected language. Your
                        <br className="d-none d-lg-block" /> current accent is set to: {accentName}
                    </p>
                    <select value={accentId} onChange={this.handleAccentChange} disabled={!accents.length}>
                        {accents.map(accent => (
                            <option key={accent.id} value={accent.id}>{accent.name}</option>
                        ))}
                    </select>
                    <br/>

                    <h5>Voice</h5>
                    <p>
                        Choose the voice for the pronunciation, which could be male or female. Your
                        <br className="d-none d-lg-block" /> current voice is set to: {voiceName}
                    </p>
                    <select value={voiceId} onChange={this.handleVoiceChange} disabled={!voices.length}>
                        {voices.map(voice => (
                            <option key={voice.id} value={voice.id}>{voice.name}</option>
                        ))}
                    </select>
                    <br/>

                    <h5>
                        Play selected Voice/Accent
                        <button type="button" onClick={this.playAudio}><img src={sound} alt="Play sample audio" /></button>
                    </h5>
                    <br/>
                    <button className="change__btn" type="submit">Save Changes</button>
                </form>
            </div>
        );
    }
}

export default GeneralSettings;
