import React, { Component } from 'react';
import plus from "../static/images/plus.svg";
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import { compose } from "redux";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

class PracticeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            selectedButtons: [],
            categoriesLoaded: false,
        };
    }

    componentDidMount() {
        this.loadCategories(); // Fetch data on initial load


        // Listen to the Bootstrap modal open event to reload data
        document.getElementById('practice-modal').addEventListener('show.bs.modal', this.loadCategories);
    }

    componentWillUnmount() {
        // Cleanup event listener when the component is unmounted
        document.getElementById('practice-modal').removeEventListener('show.bs.modal', this.loadCategories);
    }

    loadCategories = () => {
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        axios.get(`${API}${VERSION}selected-language/categories`, config)
            .then(r => {
                const data = r.data;
                const categories = data.categories;
                const selectedButtons = this.getInitialSelectedButtons(categories);
                this.setState({ categories: categories, categoriesLoaded: true, selectedButtons: selectedButtons });
            })
            .catch(e => {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
            });
    }

    onClickCategory = (event) => {
        const content = event.currentTarget.parentElement.querySelector(".accordion-contant");
        if (content) {
            const allContents = document.querySelectorAll(".accordion-contant");
            allContents.forEach(item => {
                if (item !== content) {
                    item.style.display = "none";
                }
            });
            content.style.display = (content.style.display === "none" || !content.style.display) ? "block" : "none";
        }
    }

    onClickUpdateSubCategory = (e) => {
        e.preventDefault();
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const selectedButtons = this.state.selectedButtons;
        const payload = { "subcategoryIds": selectedButtons };
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        axios.patch(`${API}${VERSION}update-user-configuration-subcategories`, payload, config)
            .then(r => {
                const data = r.data;
                // Todo: do something if subcategories were saved.
            })
            .catch(e => {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
            });
    }

    onClickSubCategory = (e, itemId) => {
        e.preventDefault();
        this.setState(prevState => {
            const isSelected = prevState.selectedButtons.includes(itemId);

            // If already selected, do nothing
            if (isSelected) {
                return null;
            } else {
                return {
                    selectedButtons: [itemId],
                };
            }
        });
    };

    getInitialSelectedButtons = (categories) => {
        let selectedButtons = [];
        categories.forEach(category => {
            category.subcategories.forEach(subcategory => {
                if (subcategory.isSelected) {
                    selectedButtons.push(subcategory.id);
                }
            });
        });
        return selectedButtons;
    };

    render() {
        const { categories, selectedButtons, categoriesLoaded } = this.state;
        return (
            <div className="modal main-modal main-modal4 main-modal6 fade" id="practice-modal"
                 aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-4 modal-dialog-6">
                    <div className="modal-content">
                        <form action="#" method="POST">
                            <div className="modal-header border-0 p-0 text-center flex-column">
                                <h5 className="modal-title mb-4 pb-1" id="staticBackdropLabel">
                                    Practice Settings
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body border-0 p-0">
                                <div className="practice___modal">
                                    {categoriesLoaded && (
                                        <>
                                            <h3>Interests</h3>
                                            <div className="accordion">
                                                {categories.map((category, index) => (
                                                    <div key={index} className="accordion-item">
                                                        <div className="accordion-titel" onClick={this.onClickCategory}>
                                                            <h2>{category.name}<img src={plus} alt="plus icon"/></h2>
                                                        </div>
                                                        <div className="accordion-contant">
                                                            <ul className="click__btn">
                                                                {category.subcategories.map(subcategory => (
                                                                    <li key={subcategory.id} className="d-inline-block">
                                                                        <button
                                                                            onClick={(e) => this.onClickSubCategory(e, subcategory.id)}
                                                                            className={`clk-btn ${selectedButtons.includes(subcategory.id) ? 'clk-btn-bg' : ''}`}
                                                                            type="button">
                                                                            <span>{subcategory.count}</span> {subcategory.name}
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between modal-footer1 border-0 p-0">
                                <button style={{width: "83px"}} className="m-0 btn-1st" type="button"
                                        data-bs-dismiss="modal">
                                    Discard
                                </button>
                                <button className="m-0 btn-2nd" data-bs-dismiss="modal"
                                        onClick={this.onClickUpdateSubCategory}>
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    languageApplicationDirection: state.language.languageApplicationDirection,
    loading: state.language.loading,
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(PracticeModal);
