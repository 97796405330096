import React, { Component } from 'react';
import sound from "../static/images/sound.png";
import rightCheckIcon from '../static/images/right-check.svg';
import closeCheckIcon from '../static/images/close-check.svg';

class TestResultModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: this.props.results,
        };
    }

    // Function to determine the icon based on grade
    getGradeIcon(isRight) {
        return isRight ? rightCheckIcon : closeCheckIcon;
    }

    // Function to play audio
    playAudio = (audioUrl) => {
        const audio = new Audio(audioUrl);
        audio.play();
    };

    render() {
        const results = this.props.results;
        const redirectHome = this.props.redirectHome;
        const resultModalRef = this.props.forwardedRef;
        return (
            <div className="modal main-modal main-modal4 main-modal44 fade" id="staticBackdrop255" tabIndex="-1"
                 aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={resultModalRef}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-4 modal-dialog-44">
                    <div className="modal-content">
                        <form action="#" method="POST">
                            <div className="modal-header border-0 p-0 text-center flex-column">
                                <h5 className="modal-title mb-5" id="staticBackdropLabel">Quiz Results</h5>
                            </div>
                            <div className="modal-body border-0 p-0">
                                <div className="spelling-div">
                                    <table className="w-100">
                                        <thead>
                                        <tr>
                                            <th>Your Spelling</th>
                                            <th>Correct Spelling</th>
                                            <th className="text-end">Results</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {results.map((result, index) => (
                                            <tr key={index}>
                                                <td className="gray-alex">{result.yourSpelling}</td>
                                                <td className="alex-td">
                                                    <img
                                                        src={sound}
                                                        alt="audio icon"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => this.playAudio(result.audioUrl)}
                                                    />
                                                    {result.correctSpelling}
                                                </td>
                                                <td className="text-center"><img src={this.getGradeIcon(result.isRight)} alt="grade" /></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between modal-footer1 border-0 p-0">
                                <button className="m-0 btn-1st" onClick={redirectHome}>Home</button>
                                <button style={{ width: '125px' }} className="m-0 btn-2nd" type="button"
                                        onClick={() => window.location.reload(true)}>Retake Quiz</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <TestResultModal {...props} forwardedRef={ref} />);
