import { setLanguageData, setApplicationLanguage, setApplicationLanguageDirection } from '../actions/language';
import textDataEn from '../languages/en.json';

export const loadLanguage = (language) => {
    return async (dispatch) => {
        let languageData;
        switch (language) {
            case 'en':
                languageData = textDataEn;
                break;
            // case 'es':
            //     languageData = await import('./path/to/textDataEs.json'); // Adjust the path as necessary
            //     break;
            // case 'fr':
            //     languageData = await import('./path/to/textDataFr.json'); // Adjust the path as necessary
            //     break;
            default:
                languageData = textDataEn;
        }
        dispatch(setLanguageData(languageData));
        dispatch(setApplicationLanguage(language));
        dispatch(setApplicationLanguageDirection(languageData.language.direction));
    };
};
