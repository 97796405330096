import React, { Component } from 'react';
import Cookies from "js-cookie";
import axios from "axios";
import { API, VERSION } from "../routers/Urls";

class PracticeSettings extends Component {
    constructor(props) {
        super(props);

        // Set initial state based on the auth prop
        this.state = {
            practiceMode: props.auth.selectedUserConfiguration.withSentence ? 'sentences' : 'words',
        };
    }

    componentDidMount() {
        // Update state if auth prop changes
        const { auth } = this.props;
        if (auth.selectedUserConfiguration) {
            this.setState({
                practiceMode: auth.selectedUserConfiguration.withSentence ? 'sentences' : 'words'
            });
        }
    }

    handlePracticeModeChange = (e) => {
        this.setState({ practiceMode: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const csrfToken = Cookies.get('csrftoken');
        const token = this.props.auth.token;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${token}`
            }
        };

        // Convert the practice mode to a boolean for 'with_sentence'
        const with_sentence = this.state.practiceMode === 'sentences';

        const data = {with_sentence: with_sentence};

        axios.post(`${API}${VERSION}update-practice-with-sentence`, data, config)
            .then(response => {
                const message = response.data.messages[0];
                this.props.alert.success(message);
            })
            .catch(error => {
                this.props.alert.error("Failed to update practice settings. Please try again.");
            });
    }

    render() {
        const { practiceMode } = this.state;
        return (
            <div className="settings-box settings-box2">
                <form onSubmit={this.handleSubmit}>
                    <h3>Practice Settings</h3>
                    <p style={{ marginBottom: '10px' }}>
                        Choose your preferred practice mode:
                    </p>
                    <p style={{ marginBottom: '10px', fontSize: '14px' }}>
                        <strong>Practice Words Only:</strong> Focus on individual words to enhance vocabulary and spelling.
                    </p>
                    <p style={{ marginBottom: '15px', fontSize: '14px' }}>
                        <strong>Practice with Sentences:</strong> Practice words in context with sentences, improving both vocabulary and comprehension.
                    </p>
                    <select value={practiceMode} onChange={this.handlePracticeModeChange} style={{ marginBottom: '20px' }}>
                        <option value="words">Practice Words Only</option>
                        <option value="sentences">Practice with Sentences</option>
                    </select>
                    <button className="change__btn" type="submit">Save Changes</button>
                </form>
            </div>
        );
    }
}

export default PracticeSettings;
