import { SET_CURRENT_SESSION_VOCAB, SET_CURRENT_SESSION_ID, SET_CURRENT_SESSION, SET_PRACTICE_PATTERN } from '../actions/types';

const initialState = {
    currentSessionVocab: [],
    currentSessionId: null,
    currentPracticeSession: null,
    practicePattern: []  // Add this line to your initial state
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CURRENT_SESSION_VOCAB:
            return {
                ...state,
                currentSessionVocab: action.payload
            };

        case SET_CURRENT_SESSION:
            return {
                ...state,
                currentPracticeSession: action.payload
            };

        case SET_CURRENT_SESSION_ID:
            return {
                ...state,
                currentSessionId: action.payload
            };

        case SET_PRACTICE_PATTERN:
            return {
                ...state,
                practicePattern: action.payload
            };

        default:
            return state;
    }
}
