import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null
        };
    }

    componentDidMount() {
        // Check if the user is authenticated based on the props
        if (this.props.auth && this.props.auth.isAuthenticated) {
            // Redirect to student dashboard if authenticated
            this.setState({ redirect: '/student/dashboard' });
        } else {
            // Redirect to login if not authenticated
            this.setState({ redirect: '/login' });
        }
    }

    render() {
        const { redirect } = this.state;

        // If the redirect state is set, redirect the user
        if (redirect) {
            return <Redirect to={redirect} />;
        }

        // While the redirect is being determined, render null or a loading spinner
        return null;
    }
}

export default LandingPage;
