import React, { Component } from 'react';

class RedoTestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="modal main-modal main-modal4 main-modal5 fade" id="redo-options" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-4 modal-dialog-5">
                    <div className="modal-content">
                        <form action="#" method="POST">
                            <div className="modal-header border-0 p-0 text-center flex-column">
                                <h5 className="modal-title mb-4 pb-1" id="staticBackdropLabel">Redo Session</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body border-0 p-0"/>
                            <div style={{padding: 0}} className="modal-footer modal-footer1 justify-content-center border-0 p-0">
                                <button style={{width: '270px'}} className="m-0 mb-2 btn-2nd" type="button">Redo Quiz</button>
                                <button style={{width: '270px'}} className="m-0 btn-1st" type="button">Redo Practice</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default RedoTestModal;
