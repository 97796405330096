import {
    SET_APPLICATION_LANGUAGE,
    SET_APPLICATION_LANGUAGE_DIRECTION, SET_LANGUAGE_DATA,
    SET_PRACTICE_LANGUAGE,
    SET_PRACTICE_LANGUAGE_DIRECTION
} from "./types";

export const setApplicationLanguage = (language) => ({
    type: SET_APPLICATION_LANGUAGE,
    payload: language,
});

export const setApplicationLanguageDirection = (direction) => ({
    type: SET_APPLICATION_LANGUAGE_DIRECTION,
    payload: direction,
});

export const setPracticeLanguage = (language) => ({
    type: SET_PRACTICE_LANGUAGE,
    payload: language,
});

export const setPracticeLanguageDirection = (direction) => ({
    type: SET_PRACTICE_LANGUAGE_DIRECTION,
    payload: direction,
});

export const setLanguageData = (data) => ({
    type: SET_LANGUAGE_DATA,
    payload: data,
});
