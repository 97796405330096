import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "../pages/404Page";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import RegisterPage from "../pages/RegisterPage";
import LoginPage from "../pages/LoginPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import StudentDashboardPage from "../pages/StudentDashboardPage";
import PracticePage from "../pages/PracticePage";
import TestPage from "../pages/TestPage";
import AccountPage from  "../pages/AccountPage";
import LandingPage from  "../pages/LandingPage";

const AppRouter = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <PrivateRoute exact path="/student/dashboard" component={StudentDashboardPage} />
            <PrivateRoute exact path="/practice" component={PracticePage} />
            <PrivateRoute exact path="/quiz" component={TestPage} />
            <PrivateRoute exact path="/account" component={AccountPage} />
            <PublicRoute exact path="/reset-password" component={ResetPasswordPage} />
            <PublicRoute exact path="/register" component={RegisterPage} />
            <PublicRoute exact path="/login" component={LoginPage} />
            <Route component={PageNotFound} />
        </Switch>
    </BrowserRouter>
);


export default AppRouter;
