import {
    SET_APPLICATION_LANGUAGE,
    SET_APPLICATION_LANGUAGE_DIRECTION,
    SET_PRACTICE_LANGUAGE,
    SET_PRACTICE_LANGUAGE_DIRECTION,
    SET_LANGUAGE_DATA,
} from '../actions/types';

const initialState = {
    languageApplication: 'en',
    languageApplicationDirection: 'LTR',
    languagePractice: 'en',
    languagePracticeDirection: 'LTR',
    textData: null,
    loading: true,
};

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APPLICATION_LANGUAGE:
            return {
                ...state,
                languageApplication: action.payload,
            };
        case SET_APPLICATION_LANGUAGE_DIRECTION:
            return {
                ...state,
                languageApplicationDirection: action.payload,
            };
        case SET_PRACTICE_LANGUAGE:
            return {
                ...state,
                languagePractice: action.payload,
            };
        case SET_PRACTICE_LANGUAGE_DIRECTION:
            return {
                ...state,
                languagePracticeDirection: action.payload,
            };
        case SET_LANGUAGE_DATA:
            return {
                ...state,
                textData: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default languageReducer;
