import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import {withAlert} from 'react-alert';
import {connect} from 'react-redux';
import {compose} from "redux";
import {REGISTER_SUCCESS} from "../actions/types";
import logo from "../static/images/logo.png";
import clmImg3 from "../static/images/clm-img3.svg";
import Cookies from 'js-cookie';
import icEye from "../static/images/ic_eye.svg";
import {getText} from "../utilities/getText";


export class RegisterPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            errors: '',
            email: '',
            isPasswordShown: false,
            isEyeDivShown: false
        }
    };

    /**
     * Create a new user.
     */
    onSubmit = (e) => {
        e.preventDefault();
        const password = this.state.password;
        const email = this.state.email;
        const alert = this.props.alert;

        // Extract query parameters from the URL with fallback to an empty string
        const urlParams = new URLSearchParams(window.location.search);
        const language = urlParams.get('language');
        const accent = urlParams.get('accent');
        const voice = urlParams.get('voice');


        // Create the payload including query params
        const payload = {
            password: password,
            email: email,
            user_type: 'STUDENT',
            language: language,
            accent: accent,
            voice: voice
        };

        const csrfToken = Cookies.get('csrftoken');
        const config = { headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken } };
        const body = JSON.stringify(payload);

        axios.post(`${API}${VERSION}auth/register`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.props.dispatch({ type: REGISTER_SUCCESS, payload: data });
            this.props.history.push('/student/dashboard');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    onChangePassword = (e) => {
        const password = e.target.value;
        this.setState({password});
    }

    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({email});
    }

    showHide = () => {
        this.setState(prevState => ({
            isPasswordShown: !prevState.isPasswordShown,
            isEyeDivShown: !prevState.isEyeDivShown
        }));
    }


    render() {
        const password = this.state.password;
        const email = this.state.email;
        return (
            <main className="right-to-left">
                <section className="sign-in-area dashboard-page">
                    <div className="container">
                        <div className="sign-head">
                            <div className="d-flex align-items-center">
                                <div className="col-6">
                                    <div className="clm-head">
                                        <Link to='/student/dashboard'><img src={logo} alt="image" /></Link>
                                    </div>
                                </div>
                                <div className="col-6 d-none d-md-block">
                                    <div className="simple-text text-end">
                                        <p>{getText('RegisterPage', 'alreadyHaveAccount')} <Link to='/login'>{getText('RegisterPage', 'signIn')}</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="clm-1 d-none d-md-block">
                                <div className="text-end">
                                    <img className="clm-img" src={clmImg3} alt="image" />
                                </div>
                                <h2>{getText('RegisterPage', 'welcomeMessage')}</h2>
                            </div>
                            <div className="clm-2">
                                <div className="sign-in-form">
                                    <form className="sign__form" method="POST" onSubmit={this.onSubmit}>
                                        <h2>{getText('RegisterPage', 'register')}</h2>
                                        <p className="d-block d-md-none">{getText('RegisterPage', 'welcomeMessage')}</p>
                                        <div className="group_inp">
                                            <input type="email" onChange={this.onChangeEmail}
                                                   placeholder={getText('RegisterPage', 'emailPlaceholder')} value={email} />
                                        </div>
                                        <div className="group_inp">
                                            <input type={this.state.isPasswordShown ? "text" : "password"}
                                                   placeholder={getText('RegisterPage', 'passwordPlaceholder')}
                                                   value={password} onChange={this.onChangePassword} />
                                            <div className={`eye-div ${this.state.isEyeDivShown ? "eye-div-show" : ""}`}
                                                 onClick={this.showHide}>
                                                <img src={icEye} alt="image" />
                                            </div>
                                        </div>
                                        <div className="simple-text d-block d-md-none">
                                            <p>{getText('RegisterPage', 'alreadyHaveAccount')}
                                                <Link to='/login'>{getText('RegisterPage', 'signIn')}</Link>
                                            </p>
                                        </div>
                                        <button className="mt-md-5" type="submit">{getText('RegisterPage', 'registerButton')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    };
}

const mapStateToProps = (state) => ({
    languageApplicationDirection: state.language.languageApplicationDirection,
    loading: state.language.loading,
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(RegisterPage);
