export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGES = "CREATE_MESSAGES";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_UPDATE = "USER_UPDATE";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_TESTS = "GET_TESTS";
export const SET_APPLICATION_LANGUAGE = 'SET_APPLICATION_LANGUAGE';
export const SET_APPLICATION_LANGUAGE_DIRECTION = 'SET_APPLICATION_LANGUAGE_DIRECTION';
export const SET_PRACTICE_LANGUAGE = 'SET_PRACTICE_LANGUAGE';
export const SET_PRACTICE_LANGUAGE_DIRECTION = 'SET_PRACTICE_LANGUAGE_DIRECTION';
export const SET_LANGUAGE_DATA = 'SET_LANGUAGE_DATA';
export const SET_CURRENT_SESSION_VOCAB = 'SET_CURRENT_SESSION_VOCAB';
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';
export const SET_CURRENT_SESSION_ID = 'SET_CURRENT_SESSION_ID';
export const SET_SELECTED_USER_CONFIGURATION = 'SET_SELECTED_USER_CONFIGURATION';
export const SET_PRACTICE_PATTERN = 'SET_PRACTICE_PATTERN';



