import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { API, VERSION } from "../routers/Urls";
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { compose } from "redux";
import {
    LOGOUT_SUCCESS,
    SET_CURRENT_SESSION,
    SET_CURRENT_SESSION_VOCAB,
    SET_SELECTED_USER_CONFIGURATION,
    SET_PRACTICE_PATTERN
} from "../actions/types";
import logo from "../static/images/logo.png";
import settingsButton from "../static/images/settings-btn.svg";
import question from "../static/images/question.svg";
import contactSupport from "../static/images/contact-support.svg";
import menuBook from "../static/images/menu-book.svg";
import modelTraining from "../static/images/model-training.svg";
import manWork from "../static/images/man-work.svg";
import PracticeModal from "../components/PracticeModal";
import ResultModal from "../components/ResultModal";
import WordListModal from "../components/WordListModal";
import RedoTestModal from "../components/RedoTestModal";

class StudentDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: [],
            sessionsLoaded: false,
            selectedSessions: null,
            showPracticeModal: false,
            loadingPen: false,
            redirectPracticePage: false,
            wordListLoading: false,
            totalLearned: 0,
            totalPracticed: 0,
            languageSelected: null,
            words: [],
            results: [],
            sortOrder: 'New',
            isCompletedFilter: 'All',
            loadingConfiguration: true,
            loadingSessions: true
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.add('Instituation-body');
        this.loadSessions();
        this.loadSelectedConfiguration();
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('Instituation-body');
    }

    loadSelectedConfiguration = () => {
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        axios.get(`${API}${VERSION}selected-configuration`, config).then(r => {
            if (this._isMounted) {
                const data = r.data;
                const totalLearned = data.totalLearned;
                const totalPracticed = data.totalPracticed;
                const languageSelected = data.language.name;
                this.setState({
                    totalLearned,
                    totalPracticed,
                    languageSelected,
                    loadingConfiguration: false // Configuration loaded
                });
                this.props.dispatch({ type: SET_SELECTED_USER_CONFIGURATION, payload: data });
            }
        }).catch(e => {
            if (this._isMounted) {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
                this.setState({ loadingConfiguration: false }); // End loading even if there's an error
            }
        });
    }

    loadSessions = () => {
        const { sortOrder, isCompletedFilter } = this.state;
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        const params = { order: sortOrder };

        if (isCompletedFilter !== 'All') {
            params.is_completed = isCompletedFilter === 'Completed';
        }

        axios.get(`${API}${VERSION}retrieve-practice-sessions`, { headers: config.headers, params }).then(r => {
            if (this._isMounted) {
                const data = r.data;
                const results = data.results;
                this.setState({ sessions: results, sessionsLoaded: true, loadingSessions: false }); // Sessions loaded
            }
        }).catch(e => {
            if (this._isMounted) {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
                this.setState({ loadingSessions: false }); // End loading even if there's an error
            }
        });
    }

    handleSortOrderChange = (e) => {
        this.setState({ sortOrder: e.target.value }, this.loadSessions);
    }

    handleIsCompletedFilterChange = (e) => {
        this.setState({ isCompletedFilter: e.target.value }, this.loadSessions);
    }

    onClickPractice = (e) => {
        e.preventDefault();
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const payload = {};
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        axios.post(`${API}${VERSION}create-practice-sessions`, payload, config).then(r => {
            if (this._isMounted) {
                const data = r.data;
                const vocabularies = data.vocabularies;
                const practiceSession = data.practiceSession;
                const practicePattern = data.practicePattern;
                this.props.dispatch({ type: SET_CURRENT_SESSION_VOCAB, payload: vocabularies });
                this.props.dispatch({ type: SET_CURRENT_SESSION, payload: practiceSession });
                this.props.dispatch({ type: SET_PRACTICE_PATTERN, payload: practicePattern });
                this.setState({ redirectPracticePage: true });
            }
        }).catch(e => {
            if (this._isMounted) {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
            }
        });
    }

    onClickOpenListWord = (sessionId) => {
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };
        this.setState({ wordListLoading: true });

        axios.get(`${API}${VERSION}practice-sessions/${sessionId}/vocabularies`, config).then(r => {
            if (this._isMounted) {
                const data = r.data;
                this.setState({ words: data, wordListLoading: false });
            }
        }).catch(e => {
            if (this._isMounted) {
                this.setState({ wordListLoading: false });
                const errors = e.response.data.errors;
                alert.error(errors[0]);
            }
        });
    }

    onClickResume = (sessionId) => {
        const alert = this.props.alert;
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        axios.get(`${API}${VERSION}resume-practice-session/${sessionId}`, config).then(r => {
            if (this._isMounted) {
                const data = r.data;
                const vocabularies = data.vocabularies;
                const practiceSession = data.practiceSession;
                const practicePattern = data.practicePattern;
                this.props.dispatch({ type: SET_CURRENT_SESSION_VOCAB, payload: vocabularies });
                this.props.dispatch({ type: SET_PRACTICE_PATTERN, payload: practicePattern });
                this.props.dispatch({ type: SET_CURRENT_SESSION, payload: practiceSession });
                this.setState({ redirectPracticePage: true });
            }
        }).catch(e => {
            if (this._isMounted) {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
            }
        });
    }

    logout = () => {
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };
        axios.post(`${API}${VERSION}auth/logout`, null, config).then(r => {
            if (this._isMounted) {
                this.props.dispatch({ type: LOGOUT_SUCCESS });
                this.props.history.push('/login');
            }
        }).catch(e => {
            console.log('errors: ', e);
        });
    }

    onClickOpenResult = (sessionId) => {
        const token = this.props.auth.token;
        const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } };

        axios.get(`${API}${VERSION}practice-sessions/${sessionId}/results`, config).then(r => {
            if (this._isMounted) {
                const data = r.data;
                this.setState({ results: data });
            }
        }).catch(e => {
            if (this._isMounted) {
                const errors = e.response.data.errors;
                alert.error(errors[0]);
            }
        });
    }

    render() {
        const {
            words, results, sessions, sessionsLoaded, redirectPracticePage,
            totalLearned, languageSelected, totalPracticed,
            wordListLoading, loadingConfiguration, loadingSessions
        } = this.state;

        // Show the loader if configuration or sessions are still loading
        if (loadingConfiguration || loadingSessions) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }

        if (redirectPracticePage) {
            return <Redirect to="/practice" />;
        }

        return (
            <>
                <main className="Instituation-page dashboard-page right-to-left">
                    <header className="Instituation-header">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <div className="clm-head">
                                        <Link to='/student/dashboard'><img src={logo} alt="image" /></Link>
                                    </div>
                                </div>
                                <div className="col-6 text-end">
                                    <div className="profile-name-div">
                                        <Link to='/login' onClick={this.logout} className='student-dashboard-nav-buttons'>Logout</Link>
                                        <Link to='/account' className='student-dashboard-nav-buttons'>Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className="westmont-area jessie-page">
                        <div className="container">
                            <div className="westmont-box jessie-box">
                                <div className="d-inline-block selected-link westmont-lnk">
                                    <div className="quest-mark">
                                        <img src={question} alt="image" />
                                        <div className="quest-txt">
                                            <p>This is the language that you're currently practicing.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div><img className="wdt-sml" src={contactSupport} alt="image" /></div>
                                        <div>
                                            <p>Language Selected</p>
                                            <h2>{languageSelected}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-inline-block selected-link westmont-lnk">
                                    <div className="quest-mark">
                                        <img src={question} alt="image" />
                                        <div className="quest-txt">
                                            <p>This is the total number of words that you were able to spell without assistance.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div><img className="wdt-sml" src={menuBook} alt="image" /></div>
                                        <div>
                                            <p>Total Learned</p>
                                            <h2>{totalLearned}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-inline-block selected-link westmont-lnk">
                                    <div className="quest-mark">
                                        <img src={question} alt="image" />
                                        <div className="quest-txt">
                                            <p>This is the total number of words that you have spelled at least three times with assistance.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div><img className="wdt-sml" src={modelTraining} alt="image" /></div>
                                        <div>
                                            <p>Total Practiced</p>
                                            <h2>{totalPracticed}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {sessionsLoaded && (
                                <div>
                                    <div className='practice__dropdown_box'>
                                        <div className="sort__div">
                                            <select value={this.state.sortOrder} onChange={this.handleSortOrderChange}>
                                                <option value="New">New</option>
                                                <option value="Old">Old</option>
                                            </select>
                                            <select value={this.state.isCompletedFilter} onChange={this.handleIsCompletedFilterChange}>
                                                <option value="All">All</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Not Completed">Not Completed</option>
                                            </select>
                                        </div>

                                        <div className="practice__btn__div">
                                            <button className="practice__btn" type="button" onClick={this.onClickPractice}>Practice</button>
                                            <button className="p-0" type="button" data-bs-toggle="modal" data-bs-target="#practice-modal">
                                                <img src={settingsButton} alt="image" />
                                            </button>
                                        </div>
                                    </div>
                                    {sessions.length > 0 ? (
                                        <div className="westmont-details jessie-details">
                                            {sessions.map((session, index) => (
                                                <div key={session.id} className="d-flex align-items-center">
                                                    <div className="westmont-radio session-content">
                                                        <h5>Practice Session {index + 1}</h5>
                                                    </div>
                                                    <div className="status-div">
                                                        <p>Status:
                                                            <span data-bs-target='#redo-options'
                                                                  className={session.isCompleted ? 'green-letter' : 'red-letter'}>
                                                                {session.isCompleted ? 'Completed' : 'Not Completed'}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="westmont-score">
                                                        <button type="button">
                                                            Best Score: {Math.min(Math.round(session.bestScore), 100)}%
                                                        </button>
                                                    </div>
                                                    <div className="westmont-results">
                                                        <button type="button" data-bs-toggle="modal" data-bs-target='#results-modal' onClick={() => this.onClickOpenResult(session.id)}>
                                                            Results
                                                        </button>
                                                    </div>
                                                    <div className="westmont-words">
                                                        <button type="button" data-bs-toggle="modal" onClick={() => this.onClickOpenListWord(session.id)}
                                                                data-bs-target="#list-words">
                                                            List Words
                                                        </button>
                                                    </div>
                                                    <div className="westmont-resume w-auto">
                                                        <button type="button" data-bs-target="#quiz-results"
                                                                onClick={() => this.onClickResume(session.id)}>
                                                            Resume
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="start-learn-area text-center">
                                            <img src={manWork} alt="image" />
                                            <p>Click Practice and start learning!</p>
                                            <span onClick={this.onClickPractice}> START LEARNING </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </section>
                    <PracticeModal />
                    <ResultModal results={results} />
                    <WordListModal words={words} loading={wordListLoading} />
                    <RedoTestModal />
                </main>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    languageApplicationDirection: state.language.languageApplicationDirection,
    loading: state.language.loading,
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(StudentDashboardPage);
